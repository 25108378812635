import 'babel-polyfill';
import 'bootstrap'
import axios from 'axios';
import Vue from 'vue/dist/vue.js';

import SmoothScroll from './smooth-scroll.polyfills.min.js';

import BootstrapVue from 'bootstrap-vue'

Vue.use(BootstrapVue)

var lang = new Vue({
    el: '#button-language',
    data: {
        languages: {
            cs: { location: "/cs/", label: "CZ", lang: "cs" },
            en: { location: "/", label: "EN", lang: "en" }
        },
        originLanguage: "en"
    },
    computed: {
        currentLanguage: function () {
            if (window.location.pathname !== "/" && window.location.protocol !== 'file:') {
                return this.getPathnameLang();
            } else {
                return this.isForced() ? this.originLanguage : this.getNavigatorLang();
            }
        },
        nextLanguage: function () {
            return this.currentLanguage === this.originLanguage ? this.languages.cs : this.languages.en;
        }
    },
    methods: {
        getPathnameLang: function () {
            return window.location.pathname.toString().substring(1, 3);
        },
        getNavigatorLang: function () {
            return (navigator.language || navigator.userLanguage).split("-")[0];
        },
        getLanguageUrl: function (lang) {
            var url = window.location.origin + lang.location;
            if (this.getNavigatorLang() !== lang.lang) {
                url += "?force=true";
            }
            return url;
        },
        isForced: function() {
            return window.location.search.toString().includes("force=true");
        }
    },
    mounted() {
        if (!this.isForced() && this.currentLanguage !== this.originLanguage && this.currentLanguage !== this.getPathnameLang()) {
            window.location.replace(this.getLanguageUrl(this.languages[this.currentLanguage]));
        }
    }
});

var scroll_mailto = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    clip: true,
    easing: 'easeInOutCubic'
});

var scroll_down = new SmoothScroll('#button-down', {
    speed: 1000,
    clip: true,
    easing: 'easeInOutCubic',
    offset: 33
});

function showMenu() {
    var content = document.getElementById("menu-content")
    var classes = content.className ? content.className.split(" ") : []

    if (!classes.includes("show")) {
        classes.push("show")
        content.className = classes.join(" ")
    }
}

function hideMenu() {
    var content = document.getElementById("menu-content")
    var classes = content.className ? content.className.split(" ") : []

    if (classes.includes("show")) {
        classes.splice(classes.indexOf("show"), 1)
        content.className = classes.join(" ")
    }
}

// menu toggler events
document.getElementById("menu-toggler").addEventListener("touchstart", evt => {
    var content = document.getElementById("menu-content")
    var classes = content.className ? content.className.split(" ") : []

    if (classes.includes("show")) {
        classes.splice(classes.indexOf("show"), 1)
    } else {
        classes.push("show")
    }

    content.className = classes.join(" ")
})

document.getElementById("menu-toggler").addEventListener("mouseover", showMenu)
document.getElementById("menu-toggler").addEventListener("mouseleave", hideMenu)

document.getElementById("menu-content").addEventListener("mouseover", showMenu)
document.getElementById("menu-content").addEventListener("mouseleave", hideMenu)

require("./publication.js");

var publications = new Vue({
    el: "#publikace",
    data: {
        publications: {
            cs: [
                {
                    date: "2023-04-19",
                    title: "Mapování ruských dodavatelů ve veřejných zakázak EU",
                    featured: false,
                    press: "https://docs.google.com/document/d/1WnKNNEroeSf9E0Lemgz6VOL4iPv9BBI0kqSZQE104Cc/edit#",
                    doc: "https://drive.google.com/file/d/1ONQ7CAGNdstYrTQaQbX6XIMUW2il-Cy_/view",
                    link: null,
                    data: null
                },
                {
                    date: "2022-11-21",
                    title: "Zindex - Krabice od vína pro nejlepší města a kraje",
                    featured: false,
                    press: "https://docs.google.com/document/d/1fffebRp7pGj18lN8r_rHHHrMR-eIzpgJFbp8hgAaiXo/edit#",
                    doc: null,
                    link: "https://datlab.eu/blog/krabice-od-vina-za-dobre-zakazky-zindex-2022/",
                    data: "https://www.zindex.cz/"
                },
                {
                    date: "2022-10-19",
                    title: "Příručka vendor lock",
                    featured: false,
                    press: null,
                    doc: "/files/2022/10/příručka_vendor_lock.pdf",
                    link: null,
                    data: null
                },
                {
                    date: "2022-05-19",
                    title: "Datlab Sankce - nástroj pro ověřování dodavatelů",
                    featured: true,
                    press: "https://datlab.eu/blog/nas-nastroj-rychle-proveri-dodavatele-proti-ruskym-sankcnim-seznamum/",
                    doc: null,
                    link: "https://sankce.datlab.eu/",
                    data: null
                },
                {
                    date: "2022-03-16",
                    title: "Zindex - státní správa a firmy",
                    featured: true,
                    press: "https://docs.google.com/document/d/1pHwMGnVPUvZCo6znE2KX6LQcIOs7ND-rs_cCtCKBe84/edit",
                    doc: null,
                    link: "https://datlab.eu/blog/novy-zindex-statni-sprava-jak-vznikal/",
                    data: "https://www.zindex.cz/"
                },
                {
                    date: "2020-08-17",
                    title: "Místní dopad veřejných investic",
                    featured: false,
                    press: null,
                    doc: "https://econlab.cz/files/2020/08/econlab_lokalni_dodavatele_studie.pdf",
                    link: "https://blog.econlab.cz/zakazky-mistnim-dodavatelum/",
                    data: null,

                },
                {
                    date: "2020-07-13",
                    title: "Průzkum trhu",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://blog.econlab.cz/pruzkum-trhu-jak-na-nej/",
                    data: null,

                },
                {
                    date: "2020-06-01",
                    title: "Výroční zpráva 2019",
                    featured: false,
                    press: null,
                    doc: "/files/2020/06/econlab_vyrocni_zprava_2019.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2020-05-12",
                    title: "Centrální nákup roušek",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://blog.econlab.cz/stat-si-sam-zakazal-centralne-nakupovat-rousky/",
                    data: null,

                },
                {
                    date: "2020-04-14",
                    title: "Přezkum u VZMR",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://blog.econlab.cz/prezkum-u-zakazek-maleho-rozsahu-coze/",
                    data: null,

                },
                {
                    date: "2020-04-02",
                    title: "Hodnocení slovenské státní správy",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://www.zindex.sk/category/detail/STATNI_SPRAVA/10/",
                    data: null,

                },
                {
                    date: "2020-04-01",
                    title: "Uzavřená a Otevřená výzva",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://blog.econlab.cz/otevrena-a-uzavrena-vyzva/",
                    data: null,

                },
                {
                    date: "2020-02-27",
                    title: "Metodika pro zakázky malého rozsahu",
                    featured: false,
                    press: "/files/2020/02/TZ_Nova_pravidla_pro_male_zakazky_ulevi_obcim_ReSt_EconLab_Oživeni.docx",
                    doc: "/files/2020/02/Metodika-zadavani-verejnych-zakazek-maleho-rozsahu-final.docx",
                    link: "https://blog.zindex.cz/mala-revoluce-ve-verejnych-zakazkach/",
                    data: null,

                },
                {
                    date: "2019-11-06",
                    title: "Zrychlení ÚOHS",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://blog.econlab.cz/uohs-rozhoduje-nejrychleji-v-historii-i-diky-econlabu/",
                    data: null,

                },
                {
                    date: "2019-10-01",
                    title: "Hodnocení zadavatelů státní správy",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://www.zindex.cz/category/detail/STATNI_SPRAVA/13/",
                    data: null,

                },
                {
                    date: "2019-07-22",
                    title: "Hodnotící kritéria ve veřejných zakázkách",
                    featured: false,
                    press: null,
                    doc: "/files/2019/07/2019-07-22-MPSV%20-%20studie%20kriteria.pdf",
                    link: "/files/2019/07/2019-07-22-Analýza%20kritérií%20-%20prezentace%20-%20MPSV.pdf",
                    data: null,

                },
                {
                    date: "2018-09-05",
                    title: "zIndex – žebříček měst ve veřejných zakázkách",
                    featured: false,
                    press: "/files/2014/10/2018-09-05-TZ-zindex-mesta-2015-2017-final.docx",
                    doc: null,
                    link: "https://www.zindex.cz",
                    data: null,

                },
                {
                    date: "2018-08-30",
                    title: "Suché období – analýza konkurence ve veřejných zakázkách",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://medium.com/zindex/ve%C5%99ejn%C3%A9-zak%C3%A1zky-2018-such%C3%A9-obdob%C3%AD-b68a3b1f798b",
                    data: null,

                },
                {
                    date: "2018-01-22",
                    title: "Odhad velikostí prezidentských kampaní",
                    featured: false,
                    press: null,
                    doc: "/files/2018/01/2017-01-22-velikosti-prezidentskych-kampani.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2017-10-02",
                    title: "Vysvědčení politických stran",
                    featured: false,
                    press: "/files/2014/10/2017-10-02-TZ-hodnoceni-politickych-stran.docx",
                    doc: null,
                    link: "http://www.politickefinance.cz/",
                    data: null,

                },
                {
                    date: "2017-09-05",
                    title: "Metodika hodnocení politických stran",
                    featured: false,
                    press: "/files/2014/10/2017-09-05-TZ-hodnoceni-politickych-stran.docx",
                    doc: "/files/2014/10/Econlab-Metodika-hodnocení-financování-politických-stran.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2017-01-26",
                    title: "Mýty a fakta o rozhodování ÚOHS",
                    featured: false,
                    press: "/files/2014/10/2017-01-26-TZ-uohs.docx",
                    doc: "/files/2014/10/2017-01-26-studie-uohs-final.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2016-10-04",
                    title: "Zpráva o financování politických stran 2012-2015",
                    featured: false,
                    press: null,
                    doc: "/files/2014/10/2016-10-04-financovani_politickych_stran.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2016-09-14",
                    title: "Hodnocení zakázek krajů",
                    featured: false,
                    press: "/files/2016/09/2016-09-14-TZ-zindex-kraje-2013-2015.docx",
                    doc: null,
                    link: "http://www.zindex.cz/",
                    data: "/files/2014/10/2016-09-14-zindex-kraje-mesta-priloha-TZ.xlsx",

                },
                {
                    date: "2016-04-29",
                    title: "Centrální zadávání v ČR",
                    featured: false,
                    press: null,
                    doc: "/files/2016/04/2016-04-28-Collaborative-Purchasing-in-Czech-Republic-final.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2016-02-15",
                    title: "Hodnocení zakkázek státních firem",
                    featured: false,
                    press: "/files/2014/10/2016-02-15-TZ-hodnoceni-zakazek-statnich-firem-final.docx",
                    doc: null,
                    link: "http://www.zindex.cz/",
                    data: "/files/2016/02/zIndex_statni_firmy_2012-2014.xlsx",

                },
                {
                    date: "2016-01-26",
                    title: "Zakázky bez soutěže 2015",
                    featured: false,
                    press: null,
                    doc: "/files/2016/01/2016-01-25-single-bids.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2015-12-09",
                    title: "Chyby na profilech zadavatele",
                    featured: false,
                    press: null,
                    doc: "/files/2015/12/prohlaseni_uohs_econlab_oziveni.docx",
                    link: null,
                    data: null,

                },
                {
                    date: "2015-11-23",
                    title: "Chyby na profilech zadavatele",
                    featured: false,
                    press: "/files/2015/11/2015-11-23-TZ-chyby-na-profilech-zadavatelu.docx",
                    doc: null,
                    link: "http://www.profily.info/",
                    data: "/files/2015/11/profily_zebricek.xlsx",

                },
                {
                    date: "2015-09-18",
                    title: "Odhad dopadu pozměňovacích návrhů k zákonu o registru smluv",
                    featured: false,
                    press: null,
                    doc: null,
                    link: null,
                    data: "/files/2015/09/pozmenovaci_navrhy_odtemneni.xlsx",

                },
                {
                    date: "2015-05-21",
                    title: "Financování politických stran 2014",
                    featured: false,
                    press: null,
                    doc: "http://www.politickefinance.cz/documents/2015-05-20-report_financovani_politickych_stran_2014_final.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2015-04-28",
                    title: "zIndex: Hodnocení měst podle dobré praxe ve veřejných zakázkách – studie",
                    featured: false,
                    press: null,
                    doc: "/files/2014/10/zIndex-for-municipalities-study.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2015-04-06",
                    title: "Fact sheet k financování politických stran",
                    featured: false,
                    press: null,
                    doc: "/files/2014/10/2015-04-06-polfin-factsheet.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2015-03-21",
                    title: "Stanovisko Transparency, Oživení a CAE k novému zákonu o zadávacích řízeních",
                    featured: false,
                    press: null,
                    doc: "/files/2014/10/Zákon-o-zadávacích-rizenich-stanovisko-20_3_2015.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2015-03-04",
                    title: "Stínový výpočet RIA k registru smluv",
                    featured: false,
                    press: null,
                    doc: "/files/2014/10/2015-03-04-stinovy-vypocet-ria-k-registru-smluv.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2014-11-28",
                    title: "Financování politických stran",
                    featured: false,
                    press: "/files/2014/12/2014-11-27-tz-dary-politickym-stranam-uoou.pdf",
                    doc: "/files/2014/11/2014-11-27-studie_dary_politickym_stranam_final.pdf",
                    link: "http://www.politickefinance.cz/",
                    data: null,

                },
                {
                    date: "2014-10-15",
                    title: "Hodnocení zakázek měst 2011-2013 zIndex",
                    featured: false,
                    press: "/files/2014/10/2014-15-10-tz-zindex-hodnoceni-zakazek-mest-2011-2013.pdf",
                    doc: "/files/2014/10/2014-15-10-zIndex_prezentace_final.pdf",
                    link: "http://www.zindex.cz/",
                    data: null,

                },
                {
                    date: "2014-09-04",
                    title: "Firemní dary politickým stranám",
                    featured: false,
                    press: "/files/old/2014-09-04-tz-dary-politickym-stranam-po.pdf",
                    doc: "/files/old/2014-09-04-studie_dary_politickym_stranam_po.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2014-06-19",
                    title: "Hromadné nedokládání vlastníků žadatelů o dotace",
                    featured: false,
                    press: "/files/old/2014-06-19-tz-dotace-bez-dolozeni-vlastniku-mohou-byt-protipravni.pdf",
                    doc: "/files/old/principy_dokladani_vlastniku_podle_zakona_218.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2014-06-11",
                    title: "Kontrola zveřejňování smluv na profilech zadavatele",
                    featured: false,
                    press: "/files/old/TZ_5procent_smluv_chybi_na_profilech_zadavatele.pdf",
                    doc: null,
                    link: null,
                    data: "files/old/publikace_smluv_na_profilech.xlsx",

                },
                {
                    date: "2014-02-20",
                    title: "Studie o vlivu novely zákona na útlum trhu VZ",
                    featured: false,
                    press: null,
                    doc: "/files/old/2014-02-20-studie-objem-zakazek.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2013-11-05",
                    title: "S AV ČR spouštíme web o financování politických stran",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "http://www.politickefinance.cz/",
                    data: null,

                },
                {
                    date: "2013-09-11",
                    title: "Veřejné podniky a politická kontrola",
                    featured: false,
                    press: null,
                    doc: "/files/2017/11/2013-09-10-SOEs-studie.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2013-05-28",
                    title: "Případová studie Česká pošta",
                    featured: false,
                    press: null,
                    doc: "/files/old/2013-05-27-ceska-posta-position-paper.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2013-04-15",
                    title: "Platy ve státní správě",
                    featured: false,
                    press: null,
                    doc: "/files/old/Analyza-platy-ve-statni-sprave-zakon-o-urednicich.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2013-03-11",
                    title: "Start projektu Rekonstrukce státu, na kterém se podílíme",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "http://www.rekonstrukcestatu.cz/",
                    data: null,

                },
                {
                    date: "2013-02-21",
                    title: "Rozkrývání dodavatelů veřejných zakázek",
                    featured: false,
                    press: null,
                    doc: "/files/2014/12/Rozkryvani-studie.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2013-01-22",
                    title: "Nový nástroj pro férové zveřejňování zakázek",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "http://www.zinfo.cz/",
                    data: null,

                },
                {
                    date: "2012-06-20",
                    title: "Spolu s Oživení,o.s. startujeme portál Všechnyzakázky.cz",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "http://www.vsechnyzakazky.cz/",
                    data: null,

                },
                {
                    date: "2012-04-13",
                    title: "Založili jsme sesterskou společnost pro komerční práci s daty Datlab s.r.o.",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "http://www.datlab.cz/",
                    data: null,

                },
                {
                    date: "2011-10-15",
                    title: "Přehled hospodaření státních podniků (spolu s EPS)",
                    featured: false,
                    press: null,
                    doc: "/files/old/SOEs-final.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2011-05-18",
                    title: "Akcie na majitele ve veřejných zakázkách 2007-2010",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "http://blog.aktualne.centrum.cz/blogy/jiri-skuhrovec.php?itemid=13160",
                    data: null,

                },
                {
                    date: "2011-03-07",
                    title: "IKEM – zakázka neznámé firmě za 437 mil.",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "http://wiki.zindex.cz/doku.php?id=ikem_-_zakazka_nezname_firme_za_437_mil",
                    data: null,

                },
                {
                    date: "2011-03-03",
                    title: "Konec losovaček v Čechách?",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "http://blog.aktualne.centrum.cz/blogy/jiri-skuhrovec.php?itemid=12410",
                    data: null,

                },
                {
                    date: "2011-01-25",
                    title: "pilotní publikace hodnocení zadavatelů zIndex",
                    featured: false,
                    press: null,
                    doc: "/files/old/110126-ZINDEX-MEDIA.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2010-06-01",
                    title: "J. Chvalkovská, J. Skuhrovec : Measuring transparency in public spending: Case of Czech Public e-Procurement Information System (první verze metodiky hodnocení zIndex, anglicky)",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "http://ies.fsv.cuni.cz/sci/publication/show/id/4063/lang/cs",
                    data: null,


                },
            ],
            en: [
                {
                    date: "2023-04-19",
                    title: "Mapping the Russian suppliers in EU procurement",
                    featured: false,
                    press: "http://datlab.eu/blog/public-tenders-worth-eu2-5-billion-awarded-to-companies-tied-to-russia-since-february-2022/",
                    doc: "https://drive.google.com/file/d/1ONQ7CAGNdstYrTQaQbX6XIMUW2il-Cy_/view",
                    link: null,
                    data: null
                },
                {
                    date: "2022-11-21",
                    title: "Zindex - municipalities procurement ranking",
                    featured: false,
                    press: "https://docs.google.com/document/d/1fffebRp7pGj18lN8r_rHHHrMR-eIzpgJFbp8hgAaiXo/edit#",
                    doc: null,
                    link: "https://datlab.eu/blog/krabice-od-vina-za-dobre-zakazky-zindex-2022/",
                    data: "https://www.zindex.cz/"
                },
                {
                    date: "2022-05-19",
                    title: "Datlab Sankce - sanctioned companies lookup tool",
                    featured: true,
                    press: "https://datlab.eu/blog/nas-nastroj-rychle-proveri-dodavatele-proti-ruskym-sankcnim-seznamum/",
                    doc: null,
                    link: "https://sankce.datlab.eu/",
                    data: null
                },
                {
                    date: "2022-03-16",
                    title: "Zindex - public administration and companies procurement rating",
                    featured: true,
                    press: "https://docs.google.com/document/d/1pHwMGnVPUvZCo6znE2KX6LQcIOs7ND-rs_cCtCKBe84/edit",
                    doc: null,
                    link: "https://datlab.eu/blog/novy-zindex-statni-sprava-jak-vznikal/",
                    data: "https://www.zindex.cz/"
                },
                {
                    date: "2020-08-17",
                    title: "Local impact of public investment",
                    featured: false,
                    press: null,
                    doc: "https://econlab.cz/files/2020/08/econlab_lokalni_dodavatele_studie.pdf",
                    link: "https://blog.econlab.cz/zakazky-mistnim-dodavatelum/",
                    data: null,

                },
                {
                    date: "2020-07-13",
                    title: "Market research",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://blog.econlab.cz/pruzkum-trhu-jak-na-nej/",
                    data: null,

                },
                {
                    date: "2020-06-01",
                    title: "Annual report 2019",
                    featured: false,
                    press: null,
                    doc: "/files/2020/06/econlab_vyrocni_zprava_2019.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2020-05-12",
                    title: "Face masks central purchases",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://blog.econlab.cz/stat-si-sam-zakazal-centralne-nakupovat-rousky/",
                    data: null,

                },
                {
                    date: "2020-04-14",
                    title: "Small tenders oversight",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://blog.econlab.cz/prezkum-u-zakazek-maleho-rozsahu-coze/",
                    data: null,

                },
                {
                    date: "2020-04-01",
                    title: "Closed and open calls",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://blog.econlab.cz/otevrena-a-uzavrena-vyzva/",
                    data: null,

                },
                {
                    date: "2020-02-04",
                    title: "Slovak public buyer rating - government",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://www.zindex.sk/category/detail/STATNI_SPRAVA/10/",
                    data: null,

                },
                {
                    date: "2019-11-06",
                    title: "Competition office speed up",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://blog.econlab.cz/uohs-rozhoduje-nejrychleji-v-historii-i-diky-econlabu/",
                    data: null,

                },
                {
                    date: "2019-10-01",
                    title: "Czech public buyer rating - government",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://www.zindex.cz/category/detail/STATNI_SPRAVA/13/",
                    data: null,

                },
                {
                    date: "2019-07-22",
                    title: "Award criteria in public procurement",
                    featured: false,
                    press: null,
                    doc: "/files/2019/07/2019-07-22-MPSV%20-%20studie%20kriteria.pdf",
                    link: "https://blog.datlab.eu/can-government-pick-quality-supplier/",
                    data: null,

                },
                {
                    date: "2018-09-05",
                    title: "zIndex – municipalities benchmarking",
                    featured: false,
                    press: "/files/2014/10/2018-09-05-TZ-zindex-mesta-2015-2017-final.docx",
                    doc: null,
                    link: "https://www.zindex.cz/",
                    data: null,

                },
                {
                    date: "2018-08-30",
                    title: "Dry season: analysis of competition for public procurement",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "https://medium.com/zindex/ve%C5%99ejn%C3%A9-zak%C3%A1zky-2018-such%C3%A9-obdob%C3%AD-b68a3b1f798b",
                    data: null,

                },
                {
                    date: "2018-01-22",
                    title: "Estimate of Presidential Campaign Sizes",
                    featured: false,
                    press: null,
                    doc: "/files/2018/01/2017-01-22-velikosti-prezidentskych-kampani.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2017-10-02",
                    title: " Rating of political parties",
                    featured: false,
                    press: "/files/2014/10/2017-10-02-TZ-hodnoceni-politickych-stran.docx",
                    doc: null,
                    link: "http://www.politickefinance.cz/en/",
                    data: null,

                },
                {
                    date: "2017-09-05",
                    title: "Metodika hodnocení politických stran",
                    featured: false,
                    press: "/files/2014/10/2017-09-05-TZ-hodnoceni-politickych-stran.docx",
                    doc: "/files/2014/10/Econlab-Metodika-hodnocení-financování-politických-stran.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2017-01-26",
                    title: "Myths and Facts on Remedies in Public Procurement",
                    featured: false,
                    press: null,
                    doc: "/files/2014/10/2017-01-26-studie-uohs-final.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2016-10-04",
                    title: "Political Party Financing Report 2012-2015",
                    featured: false,
                    press: null,
                    doc: "/files/2014/10/2016-10-04-financovani_politickych_stran.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2016-09-14",
                    title: "Benchmarking procurement in Regions",
                    featured: false,
                    press: "/files/2016/09/2016-09-14-TZ-zindex-kraje-2013-2015.docx",
                    doc: null,
                    link: "http://www.zindex.cz/",
                    data: "/files/2014/10/2016-09-14-zindex-kraje-mesta-priloha-TZ.xlsx",

                },
                {
                    date: "2016-04-29",
                    title: "Central purchasing in Czech Republic",
                    featured: false,
                    press: null,
                    doc: "/files/2016/04/2016-04-28-Collaborative-Purchasing-in-Czech-Republic-final.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2016-02-15",
                    title: "Benchmarking procurement in state-owned enterprises",
                    featured: false,
                    press: "/files/2014/10/2016-02-15-TZ-hodnoceni-zakazek-statnich-firem-final.docx",
                    doc: null,
                    link: "http://www.zindex.cz/",
                    data: "/files/2016/02/zIndex_statni_firmy_2012-2014.xlsx",

                },
                {
                    date: "2016-01-26",
                    title: "Single-bid procurement 2015",
                    featured: false,
                    press: null,
                    doc: "/files/2016/01/2016-01-25-single-bids-en.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2015-12-09",
                    title: "Mistakes on Buyers’ profiles",
                    featured: false,
                    press: null,
                    doc: "/files/2015/12/prohlaseni_uohs_econlab_oziveni.docx",
                    link: null,
                    data: null,

                },
                {
                    date: "2015-11-23",
                    title: "Mistakes on Buyers’ profiles",
                    featured: false,
                    press: "/files/2015/11/2015-11-23-TZ-chyby-na-profilech-zadavatelu.docx",
                    doc: null,
                    link: "http://www.profily.info/",
                    data: "/files/2015/11/profily_zebricek.xlsx",

                },
                {
                    date: "2015-09-18",
                    title: "Impact assesment of ammendments to the Act on Contracts Regsitry",
                    featured: false,
                    press: null,
                    doc: null,
                    link: null,
                    data: "/files/2015/09/pozmenovaci_navrhy_odtemneni.xlsx",

                },
                {
                    date: "2015-05-21",
                    title: "Political Party Financing 2014",
                    featured: false,
                    press: null,
                    doc: "http://www.politickefinance.cz/documents/2015-05-20-report_financovani_politickych_stran_2014_final.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2015-04-28",
                    title: "zIndex: Benchmarking of municipality procurement – study",
                    featured: false,
                    press: null,
                    doc: "/files/2014/10/zIndex-for-municipalities-study.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2015-04-06",
                    title: "Fact sheet on political party financing",
                    featured: false,
                    press: null,
                    doc: "/files/2014/10/2015-04-06-polfin-factsheet.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2015-03-21",
                    title: "Joint NGO position to new procurement law",
                    featured: false,
                    press: null,
                    doc: "/files/2014/10/Zákon-o-zadávacích-rizenich-stanovisko-20_3_2015.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2015-03-04",
                    title: "Shadow RIA calculation for public contracts registry",
                    featured: false,
                    press: null,
                    doc: "/files/2014/10/2015-03-04-stinovy-vypocet-ria-k-registru-smluv.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2014-11-28",
                    title: "Financing of political parties (EN)",
                    featured: false,
                    press: "/files/2015/01/Who-finances-politics-in-Czech-republic.pdf",
                    doc: "/files/2015/03/2015-01-12-Analysis-of-Czech-Political-Party-Donations.pdf",
                    link: "http://www.politickefinance.cz/en/",
                    data: null,

                },
                {
                    date: "2014-10-15",
                    title: "Municipalities procurement rating 2011-2013 zIndex",
                    featured: false,
                    press: "/files/2014/10/2014-15-10-tz-zindex-hodnoceni-zakazek-mest-2011-2013.pdf",
                    doc: "/files/2014/10/2014-15-10-zIndex_prezentace_final.pdf",
                    link: "http://www.zindex.cz/",
                    data: null,

                },
                {
                    date: "2014-09-04",
                    title: "Corporate Donations to Political Parties",
                    featured: false,
                    press: "/files/old/2014-09-04-tz-dary-politickym-stranam-po.pdf",
                    doc: "/files/old/2014-09-04-studie_dary_politickym_stranam_po.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2014-06-19",
                    title: "How EU donation recievers break law by not disclosing beneficial owners",
                    featured: false,
                    press: "/files/old/2014-06-19-tz-dotace-bez-dolozeni-vlastniku-mohou-byt-protipravni.pdf",
                    doc: "/files/old/principy_dokladani_vlastniku_podle_zakona_218.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2014-02-20",
                    title: "On effect of legal changes on Czech procurement volume and performance",
                    featured: false,
                    press: null,
                    doc: "/files/old/2014-02-20-studie-objem-zakazek.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2013-11-05",
                    title: "We launch website mapping Political party financing",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "http://www.politickefinance.cz/en/",
                    data: null,

                },
                {
                    date: "2013-09-11",
                    title: "Political control of SOE’s",
                    featured: false,
                    press: null,
                    doc: "/files/2017/11/2013-09-10-SOEs-studie.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2012-06-20",
                    title: "With colleagues from Oživení,o.s. we start portal mapping Czech public procurement Všechnyzakázky.cz",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "http://www.vsechnyzakazky.cz/en/",
                    data: null,


                },
                {
                    date: "2012-04-13",
                    title: "Založili jsme sesterskou společnost pro komerční práci s daty Datlab s.r.o.",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "http://www.datlab.cz/",
                    data: null,

                },
                {
                    date: "2011-10-15",
                    title: "Czech SOE’s performance overview",
                    featured: false,
                    press: null,
                    doc: "/files/old/SOEs-final.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2011-05-18",
                    title: "Bearer shares profitability in public procurement 2007-2010",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "http://blog.aktualne.centrum.cz/blogy/jiri-skuhrovec.php?itemid=13160",
                    data: null,

                },
                {
                    date: "2011-01-25",
                    title: "pilot zIndex rating of Czech ministries",
                    featured: false,
                    press: null,
                    doc: "/files/old/110126-ZINDEX-MEDIA.pdf",
                    link: null,
                    data: null,

                },
                {
                    date: "2010-06-01",
                    title: "J. Chvalkovská, J. Skuhrovec : Measuring transparency in public spending: Case of Czech Public e-Procurement Information System (first draft zIndex methodology zIndex)",
                    featured: false,
                    press: null,
                    doc: null,
                    link: "http://ies.fsv.cuni.cz/sci/publication/show/id/4063/lang/cs",
                    data: null,

                },
            ],
        },
        showRecent: true,
        recentCount: 10,
        views: {
            recent: {
                value: true,
                title: { cs: "Poslední publikace", en: "Last publications" },
            },
            all: {
                value: false,
                title: { cs: "Všechny publikace", en: "All publications" },
            },
        },
        lang: "cs"
    },
    created: function () {
        // current language from lang vue app (see above)
        this.lang = lang.currentLanguage;
    },
    computed: {
        nextView: function () {
            return this.showRecent ? this.views.all : this.views.recent;
        },
        sortedPublications: function () {
            // descending order by publication date
            return this.publications[this.lang].sort((a, b) => b.date.localeCompare(a.date));
        },
        recentPublications: function () {
            return this.sortedPublications.slice(0, this.recentCount);
        },
    },
    watch: {
        nextView: function() {
            if (!this.nextView.value) {
                this.$nextTick(() => {
                    var scroll = new SmoothScroll();

                    scroll.animateScroll(this.$refs.anchor, null, {
                        speed: 1000,
                        clip: true,
                        easing: 'easeInOutCubic',
                        offset: 33
                    })
                })
            }
        }
    },
    methods: {
        getPublications: function () {
            return this.showRecent ? this.recentPublications : this.sortedPublications;
        },
    },
});
