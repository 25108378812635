import Vue from 'vue/dist/vue.js'
import moment from 'moment'

Vue.component('publication', {
    props: {
        p: {type: Object, required: true},
        dateFormat: {type: String, default: "DD.MM.YYYY"},
        lang: {type: String, default: "cs"}
    },
    data: function () {
        return {
        }
    },
    computed: {
        tooltip: function() {
            return {
                doc: {cs: "Dokument", en: "Document"},
                link: {cs: "Web", en: "Web"},
                data: {cs: "Data", en: "Data"},
                press: {cs: "Tisková zpráva", en: "Press Release"},
                featured: {cs: "Důležité", en: "Important"}
            }
        }
    },
    template:
        `<div class="publication row">
            <div class="date col-4 col-sm-2 col-xl-2">{{ p.date | dateFormatter(dateFormat) }}</div>
            <div class="title col-8 col-sm-7 col-xl-8 ">
                <a :href="p.link || p.doc || p.press || p.data" target="_blank">
                    {{ p.title }}
                    <span v-if="p.featured" class="badge bg-primary">{{ tooltip.featured[lang] }}</span>
                </a>
            </div>
            <div class="col offset-4 offset-sm-0 col-8 col-sm-3 col-xl-2 icon-col text-sm-right">
                <a v-if="p.press" :href="p.press" target="_blank" :title="tooltip.press[lang]" v-b-tooltip.hover.top>
                    <img src="/src/images/speaker.svg">
                </a>
                <img v-else class="inactive" src="/src/images/speaker.svg">

                <a v-if="p.doc" :href="p.doc" target="_blank" :title="tooltip.doc[lang]" v-b-tooltip.hover.top>
                    <img src="/src/images/doc.svg">
                </a>
                <img v-else class="inactive" src="/src/images/doc.svg">

                <a v-if="p.link" :href="p.link" target="_blank" :title="tooltip.link[lang]" v-b-tooltip.hover.top>
                    <img src="/src/images/link.svg">
                </a>
                <img v-else class="inactive" src="/src/images/link.svg">

                <a v-if="p.data" :href="p.data" target="_blank" :title="tooltip.data[lang]" v-b-tooltip.hover.top>
                    <img src="/src/images/export.svg">
                </a>
                <img v-else class="inactive" src="/src/images/export.svg">
            </div>
        </div>`,
    filters: {
        dateFormatter: function(value, format) {
            if (!value) {
                return '';
            }

            return moment(String(value)).format(format);
        }
    }
});

